.button {
  height: 48px;
  display: flex;
  justify-content: center;
  padding: 14px 24px;
  background: var(--colorLightModeAccentPrimary);
  border-radius: 50px;
  color: var(--colorGlobalWhite);
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: var(--colorLightModeAccentPressed);
  }

  &.icon {
    padding-left: 16px;

    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 8px;
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: 50%;
    }
  }
}

.icon-download {
  &::before {
    background-image: url(../images/icon-download.svg);
  }
}
