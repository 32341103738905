@mixin breakpoint-576($dir: null) {
  @if $dir==min {
    @media screen and (min-width: 577px) {
      @content;
    }
  } @else {
    @media screen and (max-width: 576px) {
      @content;
    }
  }
}
