html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --colorGlobalBlack: #000;
  --colorGlobalWhite: #fff;
  --colorLightModeAccentPrimary: #2160ff;
  --colorLightModeAccentSecondary: #d2dfff;
  --colorLightModeAccentPressed: #0038d7;
  --colorLightModeAdditionalPrimary: #ff5b19;
  --colorLightModeAdditionalPressed: #e13d0a;
  --colorLightModeYellow: #ffb800;
  --colorLightModeTextPrimary: #000;
  --colorLightModeTextSecondary: #606060;
  --colorLightModeTextTertiary: #888;
  --colorLightModeAlert: #cd0025;
  --colorLightModeAlertPressed: #740015;
  --colorLightModeCellPressed: #d1dfe1;
  --colorLightModeDivider: #d8d8d8;
  --colorLightModeBackgroundPrimary: #eff4f4;
  --colorLightModeBackgroundSecondary: #fff;
}

@font-face {
  font-family: BasierCircle;
  src: url("basiercircle-regular-webfont.23d98e09.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: BasierCircle;
  src: url("basiercircle-medium-webfont.7ed6f310.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: BasierCircle;
  src: url("basiercircle-semibold-webfont.07864c73.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

.button {
  background: var(--colorLightModeAccentPrimary);
  color: var(--colorGlobalWhite);
  border-radius: 50px;
  justify-content: center;
  height: 48px;
  padding: 14px 24px;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  transition: background .2s ease-in-out;
  display: flex;
}

.button:hover {
  background: var(--colorLightModeAccentPressed);
}

.button.icon {
  padding-left: 16px;
}

.button.icon:before {
  content: "";
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  display: inline-block;
}

.icon-download:before {
  background-image: url("icon-download.87f18d4d.svg");
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  100% {
    opacity: .3;
  }
}

.placeholder h2, .placeholder ul span, .placeholder ul p, .placeholder address, .placeholder h1 {
  background: var(--colorLightModeBackgroundPrimary);
  border-radius: 12px;
  animation: 1s ease-in-out infinite alternate pulse;
  display: block;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-family: BasierCircle, sans-serif !important;
}

body {
  background: url("suri.4bae038c.png") var(--colorLightModeBackgroundSecondary) no-repeat;
  color: var(--colorLightModeTextPrimary);
  background-position: 100% 0;
  background-size: 173px 144px;
  font-size: 16px;
  font-style: normal;
  transition: opacity .2s ease-in-out;
}

body.loading {
  pointer-events: none;
  opacity: .5;
}

header {
  z-index: 0;
  max-width: 100vw;
  position: relative;
}

header > div {
  background: url("icon-app.ed64ff1b.svg") 16px 60px / 104px 36px no-repeat;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  max-width: 802px;
  margin: 0 auto;
  padding: 132px 16px 32px;
  display: flex;
}

@media screen and (width >= 577px) {
  header > div {
    background-position: 16px 16px;
    flex-direction: row;
    align-items: flex-end;
    padding-top: 88px;
  }
}

header h1 {
  letter-spacing: -.96px;
  margin-bottom: 4px;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
}

header p {
  color: var(--colorLightModeTextSecondary);
  letter-spacing: -.16px;
  margin-bottom: 8px;
  font-weight: 500;
  line-height: normal;
}

header address {
  color: var(--colorLightModeAccentPrimary);
  background: url("icon-marker.66524dce.svg") 0 0 / 20px no-repeat;
  padding-left: 24px;
  font-weight: 500;
  line-height: 20px;
}

footer {
  color: var(--colorLightModeTextSecondary);
  flex-direction: column;
  gap: 16px;
  max-width: 802px;
  margin: 32px auto 40px;
  padding: 0 16px;
  display: flex;
}

footer a {
  color: var(--colorLightModeAccentPrimary);
  font-weight: 500;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

footer p {
  text-align: center;
  line-height: normal;
}

.content {
  z-index: 1;
  max-width: 802px;
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
}

section {
  background: var(--colorLightModeBackgroundSecondary);
  border: 1px solid var(--colorLightModeDivider);
  border-radius: 20px;
  padding: 16px 20px;
  font-size: 14px;
  box-shadow: 0 2px 4px #10353d1f;
}

section + section {
  margin-top: 16px;
}

section h2 {
  letter-spacing: -.32px;
  margin-bottom: 8px;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

section ul {
  flex-flow: wrap;
  gap: 16px;
  display: flex;
}

section ul li {
  flex-basis: 90px;
}

section ul a {
  color: var(--colorLightModeTextPrimary);
  text-decoration: none;
}

section ul a:hover {
  opacity: .8;
}

section ul a span, section ul a img {
  background: var(--colorLightModeBackgroundPrimary);
  border-radius: 12px;
  width: 90px;
  height: 120px;
  display: block;
}

section ul p {
  text-align: center;
  min-height: 34px;
  margin-top: 8px;
}

.placeholder h1 {
  width: 300px;
  height: 38px;
}

.placeholder address {
  width: 300px;
  height: 20px;
}

.placeholder ul p {
  width: 90px;
  height: 34px;
}

.placeholder h2 {
  width: 150px;
  height: 40px;
}

.hidden {
  display: none;
}

hr {
  background: var(--colorLightModeDivider);
  border: 0;
  height: 1px;
  margin: 16px -16px;
}

/*# sourceMappingURL=index.e5b52f95.css.map */
