:root {
  --colorGlobalBlack: #000000;
  --colorGlobalWhite: #ffffff;
  --colorLightModeAccentPrimary: #2160ff;
  --colorLightModeAccentSecondary: #d2dfff;
  --colorLightModeAccentPressed: #0038d7;
  --colorLightModeAdditionalPrimary: #ff5b19;
  --colorLightModeAdditionalPressed: #e13d0a;
  --colorLightModeYellow: #ffb800;
  --colorLightModeTextPrimary: #000000;
  --colorLightModeTextSecondary: #606060;
  --colorLightModeTextTertiary: #888888;
  --colorLightModeAlert: #cd0025;
  --colorLightModeAlertPressed: #740015;
  --colorLightModeCellPressed: #d1dfe1;
  --colorLightModeDivider: #d8d8d8;
  --colorLightModeBackgroundPrimary: #eff4f4;
  --colorLightModeBackgroundSecondary: #ffffff;
}
