@import 'reset';
@import 'mixins';
@import 'colors';
@import 'fonts';
@import 'button';

@keyframes pulse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

%placeholder {
  display: block;
  border-radius: 12px;
  background: var(--colorLightModeBackgroundPrimary);
  animation: pulse 1s ease-in-out infinite;
  animation-direction: alternate;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'BasierCircle', sans-serif !important;
  font-weight: 400;
}

body {
  background: url(/images/suri.png) var(--colorLightModeBackgroundSecondary) no-repeat;
  background-size: 173px 144px;
  background-position: 100% 0;
  color: var(--colorLightModeTextPrimary);
  font-size: 16px;
  font-style: normal;
  transition: opacity 0.2s ease-in-out;

  &.loading {
    pointer-events: none;
    opacity: 0.5;
  }
}

header {
  z-index: 0;
  position: relative;
  max-width: 100vw;

  & > div {
    padding: 132px 16px 32px 16px;
    max-width: 802px;
    margin: 0 auto;

    background: url(../images/icon-app.svg);
    background-size: 104px 36px;
    background-position: 16px 60px;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;

    @include breakpoint-576(min) {
      padding-top: 88px;
      flex-direction: row;
      align-items: flex-end;

      background-position: 16px 16px;
    }
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: -0.96px;
    margin-bottom: 4px;
  }

  p {
    color: var(--colorLightModeTextSecondary);
    margin-bottom: 8px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.16px;
  }

  address {
    line-height: 20px;
    padding-left: 24px;
    background: url(../images/icon-marker.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    color: var(--colorLightModeAccentPrimary);
    font-weight: 500;
  }
}

footer {
  max-width: 802px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 32px auto 40px auto;
  color: var(--colorLightModeTextSecondary);

  a {
    color: var(--colorLightModeAccentPrimary);
    font-weight: 500;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    text-align: center;
    line-height: normal;
  }
}

.content {
  padding: 0 16px;
  max-width: 802px;
  margin: 0 auto;
  z-index: 1;
  position: relative;
}

section {
  font-size: 14px;
  padding: 16px 20px;
  box-shadow: 0px 2px 4px 0px rgba(16, 53, 61, 0.12);
  border-radius: 20px;
  background: var(--colorLightModeBackgroundSecondary);
  border: 1px solid var(--colorLightModeDivider);

  & + section {
    margin-top: 16px;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.32px;
    padding: 10px 0;
    margin-bottom: 8px;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;

    li {
      flex-basis: 90px;
    }

    a {
      text-decoration: none;
      color: var(--colorLightModeTextPrimary);

      &:hover {
        opacity: 0.8;
      }

      span,
      img {
        display: block;
        height: 120px;
        width: 90px;
        background: var(--colorLightModeBackgroundPrimary);
        border-radius: 12px;
      }
    }

    p {
      margin-top: 8px;
      min-height: 34px;
      text-align: center;
    }
  }
}

.placeholder {
  h1 {
    @extend %placeholder;
    width: 300px;
    height: 38px;
  }

  address {
    @extend %placeholder;
    height: 20px;
    width: 300px;
  }

  ul {
    p {
      @extend %placeholder;
      width: 90px;
      height: 34px;
    }
    span {
      @extend %placeholder;
    }
  }

  h2 {
    @extend %placeholder;
    width: 150px;
    height: 40px;
  }
}

.hidden {
  display: none;
}

hr {
  margin: 16px -16px;
  height: 1px;
  background: var(--colorLightModeDivider);
  border: 0;
}
